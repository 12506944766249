import React from 'react';
import equal from 'fast-deep-equal';
import { Navigation } from './navigation/Navigation';
import { Post } from './post/Post';
import { ContentService } from './ContentService'

export class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      showSnippet: false,
      items: [],
      id: null,
      page: null,
    };
  }

  contentSvc = new ContentService();

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps) {
    if(!equal(this.props, prevProps)) {
      this.setState({items: [], showSnippet: false, page: null}, () => {
        this.getContent();
      });
    }
  }

  getContent() {
    var defaultTitle = 'One Acre Lott — New Adventures Every Day!';
    var currentId = null;
    var currentPage = null;

    // Get Content By Date (using URL Parameters)
    if (this.props.match && this.props.match.params && Object.keys(this.props.match.params).length) {
      var items = [];
      var year = this.props.match.params.year;
      var month = this.props.match.params.month;
      var day = this.props.match.params.day;
      var title = this.props.match.params.title;
      this.contentSvc.getContentByDate(year, month, day, title, (data) => {
        var result = JSON.parse(data.Payload);

        items = result.Items;
        if (items.length) {
          document.title = defaultTitle;
        } else {
          document.title = result.Items[0].title + ' — One Acre Lott';
          currentId = result.Items[0].id;
        }

        this.setState({
          isLoaded: true,
          items: items,
          id: currentId,
          showSnippet: !this.props.match.params.hasOwnProperty('title'),
          page: null
        });
      });
    // Get Content By Page
    } else {
      currentPage = !this.state.page ? 1 : (this.state.page + 1);
      this.contentSvc.getContentByPage(currentPage, (data) => {
        var result = JSON.parse(data.Payload);

        var items = [];
        document.title = defaultTitle;
        items = this.state.items.concat(result.Items);

        this.setState({
          isLoaded: true,
          items: items,
          id: null,
          showSnippet: true,
          page: currentPage
        });
      });
    }
  }

  render() {
    var posts = []
    if (this.state.items && this.state.items.length) {
      posts = this.state.items.map(i => {
        return <Post key={`${i.title}`} data={i} showSnippet={this.state.showSnippet} /> 
      });
    } else {
      posts = <div className="card" style={{height: '1000px'}}></div>
    }

    const showMore = this.state.page ?
      <div className="show-more" onClick={() => {this.getContent()}}>Show More</div> : null;

    return (
      <div className="row">
        <div className="blog-content">
          {posts}
          {showMore}
        </div>
        <Navigation/>
      </div>
    );
  }
}