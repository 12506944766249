import React from 'react';

export class About extends React.Component {

  componentDidMount() {
    document.title = "About One Acre Lott";
  }
  render() {
    return (
      <div className="card">
        <p><img className="about-photo" src="https://dyb9a2feudnqg.cloudfront.net/img_20160514_062133773.jpg" /></p>
        <h2>About</h2>
        <p>So you wanna know a little bit more about that crazy Lott blog, eh?</p>
        <p>Well, for starters, my name is Nathan. You can call me “Nate” for short though.</p>
        <p>FAITH and FAMILY are by far my greatest joy and my highest priority. And the way I see it, it’s kinda hard to mark where one ends and the other begins, so let’s just lump them both together in the #1 spot, shall we?</p>
        <p>Our little family of five resides on about 1.17 acres out in the country (hence the clever blog title).</p>
        <p>And we’ve found that when you’re trying to juggle a 6000+ square foot vegetable garden, a wannabe fruit orchard, a Holstein steer, a half dozen dairy goats, a few pigs, a smattering of chickens, a first-class bird dog, way too many half-wild farm cats, and a full-time job (in a city that’s 45 minutes away from home), it can make for some pretty awesome adventures!</p>
        <p>I started this little blog as a way to share some of those adventures — and maybe a few of my own philosophical meanderings along the way.</p>
        <p>That being said, thanks for visiting us!</p>
        <p>Make yourself at home, cuz dinner will be ready in a bit!</p>
      </div>
    );
  }
}