import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Header } from './components/Header';
import { About } from './components/About';
import { NoMatch } from './components/NoMatch';
import { Content } from './components/content/Content';
import { Footer } from './components/Footer';
import './App.css';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Header />
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/:year/:month/:day/:title" component={Content} />
            <Route path="/:year/:month/:day" component={Content} />
            <Route path="/:year/:month" component={Content} />
            <Route path="/:year" component={Content} />
            <Route path="/" component={Content} />
            <Route component={NoMatch} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
