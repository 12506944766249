import React from 'react';
import { Year } from './Year';

const beginningMonth = 2;
const beginningYear = 2016;
const now = new Date();

export class Archives extends React.Component {
  getYears() {
    var years = [];
    for (var i = beginningYear; i <= now.getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  getMonths(year) {
      var months = [1,2,3,4,5,6,7,8,9,10,11,12];
      if (year === beginningYear) {
        // remove previous months
        return months.slice(months.indexOf(beginningMonth));
      }

      if (year === now.getFullYear()) {
        // remove future months
        var truncatedMonths = [];
        months.forEach(m => {
          if (m <= (now.getMonth() + 1)) {
            truncatedMonths.push(m);
          }
        });
          return truncatedMonths;
      }
      return months;
  }

  getArchives() {
    var yearComponents = [];

    var years = this.getYears();
    years.forEach(year => {
      var months = this.getMonths(year);
      yearComponents.push(<Year key={year} val={year} months={months} />);
    });
    return yearComponents;
  }

  render() {
    const archives = this.getArchives();

    return (
      <div className="card">
        <h3>Archives</h3>
        {archives}
      </div>
    );
  }
}