import React from 'react';

export class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        {/* <div>Footer Goes Here</div> */}
      </div>
    );
  }
}