import React from 'react';

export class NoMatch extends React.Component {
  render() {
    return (
      <div>
        Sorry, the post you're looking for doesn't exist.
      </div>
    );
  }
}