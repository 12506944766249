import React from 'react';
import { Link } from "react-router-dom";
import { MonthUtils } from '../../../../utils/months';

export class Year extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggle() {
    this.setState({expanded: !this.state.expanded});
  }

  goToMonth(m) {
    alert(m);
  }

  render() {
    const months = this.props.months.map(m => {
      return (
        <a href={`/${this.props.val}/${m}`} className={'indented'}>
          {MonthUtils.monthNames[m - 1]}
        </a>
      )
    });

    return (
      <div>
        <div onClick={() => {this.toggle()}}>
          <i className={`arrow ${this.state.expanded ? 'down' : 'right'}`}></i>
          <div>{this.props.val}</div>
        </div>
        { this.state.expanded && months }
      </div>
    );
  }
}