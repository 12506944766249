import React from 'react';
import { ContentService } from '../ContentService';
import { MonthUtils } from "../../../utils/months";

export class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedDate: null,
      adjacentPosts: null
    };
  }

  contentSvc = new ContentService();

  componentDidMount() {
    this.setState({formattedDate: this.getFormattedDate()});
    if (!this.props.showSnippet) {
      this.getAdjacentPosts();
    }
  }

  getFormattedDate() {
    if (1 > this.props.data.month > 12) {
      return "";
    }
    var month = MonthUtils.monthNames[this.props.data.month - 1];
    var day = this.props.data.day;
    var year = this.props.data.year;
    return `${month} ${day}, ${year}`;
  }

  getAdjacentPosts() {
    let previousPostId = this.props.data.id + 1;
    let nextPostId = this.props.data.id - 1;
    this.contentSvc.getContentById(nextPostId, previousPostId, (data) => {
      var result = JSON.parse(data.Payload);
      let previousPost = result.Items.filter(i => i.id === previousPostId);
      let nextPost = result.Items.filter(i => i.id === nextPostId);
      if (previousPost.length || nextPost.length) {
        let link1Style = {paddingBottom: '10px', borderBottom: '1px solid'};
        let link1 = previousPost[0] ? <div style={link1Style}><h4>Previous</h4><a href={previousPost[0].url}><h3>{previousPost[0].title}</h3></a></div> : null;
        let link2 = nextPost[0] ? <div><h4>Next</h4><a href={nextPost[0].url}><h3>{nextPost[0].title}</h3></a></div> : null
        let adjacentPosts =  <div className="card">
                              {link1}
                              {link2}
                            </div>;

        this.setState({
          adjacentPosts: adjacentPosts
        });
      }
    });
  }

  render() {
    const url = `${this.props.data.url}`;
    const body = this.props.showSnippet ?
                   this.props.data.snippet :
                   this.props.data.body;

    return (
      <div>
        <div className="card">
          <a href={url}><h2>{this.props.data.title}</h2></a>
          <h5>Published {this.state.formattedDate}</h5>
          <div dangerouslySetInnerHTML={{__html: body}} />
        </div>
        {this.state.adjacentPosts}
      </div>
    );
  }
}