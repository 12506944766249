import * as AWS from 'aws-sdk'

export class ContentService {
  AWS = AWS;
  lambda = {};

  pullParams = {
      FunctionName : 'get_blog_posts',
      InvocationType : 'RequestResponse',
      LogType : 'None'
  };

  constructor() {
    this.AWS.config.accessKeyId = 'AKIA4QFL6HRYMPOS5Q5E';
    this.AWS.config.secretAccessKey = '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';
    this.AWS.config.region = 'us-west-1';
    this.lambda = new AWS.Lambda({region: 'us-west-1', apiVersion: '2015-03-31'});
  }

  getContentByPage(currentPage, callback) {
    this.pullParams.Payload = JSON.stringify({page: currentPage});
    this.lambda.invoke(this.pullParams, (error, data) => {
        if (error) {
            prompt(error);
        } else {
            callback(data);
        }
    });
  }

  getContentByDate(year, month, day, title, callback) {
    var params = {};
    if (year) params.year = year;
    if (month) params.month = month;
    if (day) params.day = day;
    if (title) params.title = title;

    this.pullParams.Payload = JSON.stringify(params);
    this.lambda.invoke(this.pullParams, (error, data) => {
        if (error) {
            prompt(error);
        } else {
            callback(data);
        }
    });
  }

  getContentById(minId, maxId, callback) {
    this.pullParams.Payload = JSON.stringify({minId: minId, maxId: maxId});
    this.lambda.invoke(this.pullParams, (error, data) => {
        if (error) {
            prompt(error);
        } else {
            callback(data);
        }
    });
  }
}