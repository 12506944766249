import React from 'react';
import { Archives } from './archives/Archives';
import { ContentService } from '../ContentService';

export class Navigation extends React.Component {

  contentSvc = new ContentService();

  constructor(props) {
    super(props)
    this.state = {
      recentPosts: []
    };
  }

  componentDidMount() {
    this.getRecentPosts();
  }

  getRecentPosts() {
    this.contentSvc.getContentById(1, 5, (data) => {
      var result = JSON.parse(data.Payload);
      let recentPosts = result.Items.map((item, index) => {
        const key = `recentPost_${index}`;
        return <a key={key} href={item.url}><h4>{item.title}</h4></a>;
      });
      this.setState({recentPosts: recentPosts});
    });
  }

  render() {
    return (
      <div className="blog-navigation">
        <div>

          <div className="card">
            <h2>Recent Posts</h2>
            {this.state.recentPosts}
          </div>

          <Archives />

          <div className="card">
            <h2>About Me</h2>
            <img className="my-photo" src="https://dyb9a2feudnqg.cloudfront.net/nathan-m-lott.jpg" sizes="(max-width: 150px) 100vw, 150px" />
            <p>Welcome to One Acre Lott!</p>
            <p>My name is Nate.</p>
            <p>I'm a software engineer by trade, but an adventurer-farmer at heart. My family and I live out in
              the country (on a 1.17 acre lot). We grow a pretty big garden. We keep a few farm animals.
              Adventures abound.</p>
            <a href="/about">Read More...</a>
          </div>

          {/* <div className="card">
            <h3>Facebook Widget Goes Here</h3>
            <p>Follow Along!</p>
          </div> */}
        </div>
      </div>
    );
  }
}