import React from 'react';
import { Link } from "react-router-dom";

export class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <Link to="/"><h2>One Acre Lott</h2></Link>
      </div>
    );
  }
}